import React from 'react'
import HomeSlider from '../../slider/HomeSlider'

const DesignSlider = () => {
    return (
        <>
            <div className='design-silder-section' style={{background:"rgb(239 218 204)"}}>
                <div className='container'>
                    <h3 className='text-center' style={{paddingBottom:"15px"}}>Sweets Home Interior Design Projects</h3>
                    <div className='row'>
                        <HomeSlider />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DesignSlider