import React from 'react'
import { Helmet } from 'react-helmet';

const youtube_data = [
    {
        link: "https://www.youtube.com/embed/sgEYdvH2sKI",
        lind_head: "Simple & Modern | 2BHK home interior design | Interior Design ideas |Mumbai"
    },
    {
        link: "https://www.youtube.com/embed/xm6hyInZX1s",
        lind_head: "Home Tour of 2BHK Home | Stylish home designed by Sweet Home Interior"
    },
    {
        link: "https://www.youtube.com/embed/y0cxhvEMDUU",
        lind_head: "Exploring Pune's Modern Contemporary 2BHK Home Interior"
    },
];

const InteriorProjectTour = () => {
    return (
        <>
            <Helmet>
                <title>Interior Project Tour | Sweet Interior Home</title>
                <meta name="description" content="Transform your space with Sweet Home Interiors. Our expert team provides personalized design solutions to create elegant, functional, and unique interiors that reflect your style. Discover the perfect look for your home today!" />
                <mete name='keyword' content='Interior design tour,Home interior tour package, Interior design virtual tour, Interior design tour near me, Luxury home interior tours, 3D virtual home design tour, Interior design consultation tours' />
            </Helmet>
            <div className='container'>
                 <div className='interior-section pad-tb-50'>
                    <img src='/images/interior_project.png' alt='interior project' />
                    {youtube_data.map((data,index) =>{
                        return(
                            <div className="video-responsive" key={index}>
                                <iframe
                                    width="80%"
                                    height="350"
                                    src={data.link}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                                <h2>{data.lind_head}</h2>
                            </div>
                        )
                    })}
                 </div>
            </div>
        </>
    )
}

export default InteriorProjectTour