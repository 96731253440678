import React from 'react'
import Navbar from './generic/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './components/Home/Home'
import AboutUsPage from './components/about/AboutUsPage'
import SpecialPackagesPage from './components/special-package/SpecialPackagesPage'
import ContactUs from './components/contact/ContactUs'
import Footer from './generic/Footer'
import InteriorProjectTour from './components/interior-project/InteriorProjectTour'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/special-packages" element={<SpecialPackagesPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/interior-project-tour" element={<InteriorProjectTour />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App