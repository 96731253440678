import React from 'react'
import HomeContactUs from '../Home/HomeContactUs'
import { Helmet } from 'react-helmet'

const ContactUs = () => {
    return (
        <>
            <Helmet>
                <title>Contact Us | Sweet Interior Home</title>
                <meta name="description" content="Get in touch with Sweet Home Interiors to start your design journey. Whether you have a question, need a consultation, or want to discuss your project ideas, our team is here to help. Reach out today and let us assist you in creating your dream home." />
                <meta name='keyword' content='Contact interior design company, Interior design consultation, Interior design contact Maumbai, Interior design company near me, Schedule a design consultation, Home interior consultation contact' /> 
            </Helmet>
            <div className='contact-us-section'>
                <div className='container'>
                    <HomeContactUs />
                </div>
            </div>
        </>
    )
}

export default ContactUs