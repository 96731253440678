import React from 'react'
import HomeVideoSection from './HomeVideoSection'
import HomeAbout from './HomeAbout'
import HomeSlider from '../../slider/HomeSlider'
import DesignSlider from './DesignSlider'
import HomeServices from './HomeServices'
import HomeContactUs from './HomeContactUs'
import { Helmet } from 'react-helmet';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Best Interior Designer | Sweet Interior Home</title>
                <meta name="description" content="Transform your space with Sweet Home Interiors. Our expert team provides personalized design solutions to create elegant, functional, and unique interiors that reflect your style. Discover the perfect look for your home today!" />
                <meta name="keywords" content="Interior design services, Luxury home interiors, Custom home interior design, Interior design Mumbai,Navi Mumbai, Affordable home interior solutions, Sustainable home interior designs" />
            </Helmet>
            <HomeVideoSection />
            <HomeAbout />
            <DesignSlider />
            <HomeServices />
            <HomeContactUs />
        </>
    )
}

export default Home