import React from 'react'
import { Link } from 'react-router-dom'

const HomeAbout = () => {
    return (
        <>
            <div className='home-about-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <div className='has-left'>
                                <h3>About Us</h3>
                                <p>Sweet home Interior, a leading interior design firm in Navi Mumbai, specializes in high-end residential interior designs, bungalows, Residensial and commercial spaces. As expert interior designers, our mission is to bring creative innovation to every project, crafting spaces that truly reflect our client's unique personalities. We have a passion for transforming houses into beloved homes and delivering dream-like interiors.<br/><br/></p>
                                <button><Link to="/about-us">Learn More</Link></button>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <div className='has-right'>
                                <img src='/images/home-about.jpeg' alt='home about' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeAbout