import React from 'react'

const TableList = (props) => {
    console.log("props", props.data)
    return (
        <>
            <div className=''>
                <table>
                    <thead>
                        <tr>
                            <th><label>Sr.</label></th>
                            <th><label>First Name</label></th>
                            <th><label>Last Name</label></th>
                            <th><label>Mobile Number</label></th>
                            <th><label>Email</label></th>
                            <th><label>Address</label></th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {props.data.map((items,index) =>{
                            return(

                            )
                        })} */}
                        <tr>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default TableList