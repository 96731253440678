import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const [toggleBtn, setToggleBtn] = useState(false);
  const [menuBtn, setMenuBtn] = useState(false);
  const location = useLocation();

  const HandleToggle = () =>{
    setToggleBtn(!toggleBtn)
    setMenuBtn(false)
  }

  return (
      <>
        <div className='container-fluid pad-0' style={{position:"relative"}}>
            <nav className="navbar navbar-expand-lg navbar-light z-index-11 pad-0">
                <Link className="navbar-brand" to='/'>
                    <img src='/images/main-logo-1.png' alt="sweet home interior" />
                </Link>
                <button onClick={HandleToggle} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${toggleBtn || menuBtn == true ? "show" : null}`} id="navbarSupportedContent">
                    <span></span>
                    <ul className="navbar-nav mt-2 mt-lg-0">
                        <li className="nav-item active" onClick={HandleToggle}>
                            <Link className={`nav-link ${location.pathname === '/' && 'active-btn'}`} to="/">Home </Link>
                        </li>
                        <li className="nav-item" onClick={HandleToggle}>
                            <Link  className={`nav-link ${location.pathname === '/about-us' && 'active-btn'}`} to="/about-us">About</Link>
                        </li>
                        <li className="nav-item" onClick={HandleToggle}>
                            <Link  className={`nav-link ${location.pathname === '/special-packages' && 'active-btn'}`} to="/special-packages">Special Packages</Link>
                        </li>
                        <li className="nav-item" onClick={HandleToggle}>
                            <Link  className={`nav-link ${location.pathname === '/interior-project-tour' && 'active-btn'}`} to="/interior-project-tour">Interior Project Tour</Link>
                        </li>
                        <li className="nav-item" onClick={HandleToggle}>
                            <Link  className={`nav-link ${location.pathname === '/contact-us' && 'active-btn'}`} to="/contact-us">Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className='whatsapp-section'>
                <a href='https://api.whatsapp.com/send?phone=918318632301' target='_blank'>
                    <img src='/images/whats_app.png' alt='whatsapp' />
                </a>
            </div>
        </div>
      </>
  )
}

export default Navbar