import React from 'react'

const HomeServices = () => {
    return (
        <>
            <div className='services-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-6'>
                            <div className='ss-left'>

                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-6'>
                            <div className='ss-left'>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeServices