import React from 'react';

const HomeVideoSection = () => {
    return (
        <>
            <div className='video-section' style={{background:"rgb(239 218 204)"}}>
                <div className='container'>
                    <div className='row'>
                        <video autoPlay muted loop>
                            <source src="/videos/bg-video-1.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className='video-content'>
                            <h1>“Home is the starting place of love, hope and dreams.”</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeVideoSection;
