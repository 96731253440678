import React, { useState } from 'react';
import { FaLocationDot } from 'react-icons/fa6';
import { FaPhoneVolume } from "react-icons/fa6";
import TableList from '../../generic/TableList'

const HomeContactUs = () => {
    const [data, setData] = useState({ firstName: '', lastName: '', mobileNumber: '',email: '', address: ''});
    const [success, setSuccess] = useState("");
    const [errors, setErrors] = useState({});

    const HandleInput = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.firstName = data.firstName ? "" : "First Name is required.";
        tempErrors.lastName = data.lastName ? "" : "Last Name is required.";
        tempErrors.mobileNumber = data.mobileNumber ? "" : "Mobile Number is required.";
        tempErrors.email = data.email ? "" : "Email is required.";
        tempErrors.address = data.address ? "" : "Address is required.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setSuccess("Form submitted successfully");
            setData({firstName: '',lastName: '', mobileNumber: '', email: '', address: ''});
        } else {
            console.log("Validation failed.");
        }
    };

    console.log(data)

    return (
        <>
            <div className='contact-us pad-tb-50'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-6'>
                            <div className='ccs-left'>
                                <h3 className='text-center'>Contact Us</h3>
                                <div className='contact-form relative'>
                                    <form onSubmit={handleSubmit}>
                                        <div className='relative'>
                                            <input type='text' name='firstName' placeholder='First Name' onChange={HandleInput} value={data.firstName} />
                                            {errors.firstName && <span className="error">{errors.firstName}</span>}
                                        </div>
                                        <div className='relative'>
                                            <input type='text' name='lastName' placeholder='Last Name' onChange={HandleInput} value={data.lastName} />
                                            {errors.lastName && <span className="error">{errors.lastName}</span>}
                                        </div>
                                        <div className='relative'>
                                            <input type='text' name='mobileNumber' placeholder='Mobile Number' onChange={HandleInput} value={data.mobileNumber} />
                                            {errors.mobileNumber && <span className="error">{errors.mobileNumber}</span>}
                                        </div>
                                        <div className='relative'>
                                            <input type='text' name='email' placeholder='Email' onChange={HandleInput} value={data.email} />
                                            {errors.email && <span className="error">{errors.email}</span>}
                                        </div>
                                        <div className='relative'>
                                            <input type='text' name='address' placeholder='Address' onChange={HandleInput} value={data.address} />
                                            {errors.address && <span className="error">{errors.address}</span>}
                                        </div>
                                        {success && <p className='success'>{success}</p>}
                                        <button style={{ marginTop: "30px" }} type='submit'>Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-6'>
                            <div className='ccs-right'>
                                <h3 className='text-center'>Address</h3>
                                <div className='add-name'>
                                    <FaLocationDot />
                                    <p>Shop No. 12, Sector-4, <br />Airoli Navi Mumbai Maharashtra 400708</p>
                                </div>
                                <div className='add-name' style={{alignItems:"center"}}>
                                    <FaPhoneVolume />
                                    <p style={{paddingLeft:"10px"}}><strong>8318632301, 8425936259</strong></p>
                                </div>
                                <div>
                                    <iframe 
                                        style={{ border: "2px solid #00a79c", borderRadius: "10px" }} 
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.9340926346763!2d72.99336357466704!3d19.154362049508926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bff6e0d8dc33%3A0xb3b9fb333e7837b5!2sSweet%20Home%20Interior!5e0!3m2!1sen!2sin!4v1724230281051!5m2!1sen!2sin" 
                                        width="550" 
                                        height="300" 
                                        allowFullScreen="" 
                                        loading="lazy" 
                                        referrerPolicy="no-referrer-when-downgrade">
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <TableList data={data} /> */}
            </div>
        </>
    );
};

export default HomeContactUs;