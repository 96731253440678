import React from 'react'
import { Helmet } from 'react-helmet'

const SpecialPackagesPage = () => {
    return (
        <>
            <Helmet>
                <title>Special Packages | Sweet Interior Home</title>
                <meta name="description" content="Discover Sweet Home Interiors' Special Packages for exceptional home design solutions. Our exclusive offers include personalized design consultations, bespoke decor packages, and special discounts to help you achieve your dream space. Explore our tailored packages and find the perfect solution to transform your home with style and elegance." />
                <meta name='keyword' content='Interior design packages, Custom home design packages, Affordable interior design services, Special home interior packages, Home makeover packages, Interior design offers Mumbai(Navi Maumbi)' />
            </Helmet>
            <div className='container pad-tb-50'>
                <h3 className='text-center' style={{paddingBottom:"15px"}}>Interior Design Packages</h3>
                <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='sp-box'>
                            <img src='/images/1-bhk.jpeg' alt='1 BHK' />
                            <h4>1BHK Interior Design Package</h4>
                            <button><a href='https://api.whatsapp.com/send?phone=918318632301' target='_blank'>Get Quote</a></button>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='sp-box'>
                            <img src='/images/2-bhk.jpeg' alt='2 BHK' />
                            <h4>2BHK Interior Design Package</h4>
                            <button><a href='https://api.whatsapp.com/send?phone=918318632301' target='_blank'>Get Quote</a></button>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='sp-box'>
                            <img src='/images/3-bhk.jpeg' alt='3 BHK' />
                            <h4>3BHK Interior Design Package</h4>
                            <button><a href='https://api.whatsapp.com/send?phone=918318632301' target='_blank'>Get Quote</a></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SpecialPackagesPage