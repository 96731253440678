import React from 'react'

const Footer = () => {
    return (
        <>
            <div className='footer'>
                <div className='footer-items'>
                    <a href='https://www.linkedin.com/in/sweet-home-interior-775298325?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app' target='_blank'><img src='/images/linkedin.png' alt='linkedin' /></a>
                    <a href='https://www.facebook.com/profile.php?id=61559483393026&mibextid=ZbWKwL' target='_blank'><img src='/images/facebook.png' alt='Facebook' /></a>
                    <a href='https://www.instagram.com/sweethomeinterior41?utm_source=qr&igsh=eDJsYWU5MHd6eGZj' target='_blank'><img src='/images/instagram.png' alt='Instagram' /></a>
                    <a href='https://youtube.com/@sweethomeinteriorpvtltd?si=RcMUsVfayTaGp6Z2' target='_blank'><img src='/images/youTube.png' alt='youTube' /></a>
                </div>
                <p>©2024 by Sweet home interior</p>
            </div>
        </>
    )
}

export default Footer