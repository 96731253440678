import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function HomeSlider() {
  return (
    <>
      <Swiper
        loop={true}
        pagination={{
            type: 'fraction',
        }}
        autoplay={{
            delay: 1500,
            disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
            <img src='/images/slides/slider1.jpeg' alt='slider1' />
        </SwiperSlide>
        <SwiperSlide>
            <img src='/images/slides/slider2.jpeg' alt='slider2' />
        </SwiperSlide>
        <SwiperSlide>
            <img src='/images/slides/slider3.jpeg' alt='slider3' />
        </SwiperSlide>
        <SwiperSlide>
            <img src='/images/slides/slider4.jpeg' alt='slider4' />
        </SwiperSlide>
        <SwiperSlide>
            <img src='/images/slides/slider5.jpeg' alt='slider5' />
        </SwiperSlide>
        <SwiperSlide>
            <img src='/images/slides/silder6.jpeg' alt='slider6' />
        </SwiperSlide>
      </Swiper>
    </>
  );
}